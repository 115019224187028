<script setup lang="ts">
import { posSettingsSetInputSchema } from "@mono/validation/lib/Organization";

// composables
const { useQuery, useMutation, trpcClient } = useRequest();
const { t } = useI18n();
const snack = useSnack();

// query
const { data: posSettings, isFetching } = useQuery({
  queryKey: ["posSettings.getOne"],
  queryFn: () => trpcClient.posSettings.getOne.query(),
});

const { mutate: posSettingUpsert, isPending } = useMutation({
  mutationFn: trpcClient.posSettings.upsertOne.mutate,
  onError: () => {
    snack.error();
  },
  onSuccess: () => {
    snack.success();
  },
});

const { handleSubmit, disableSubmit, values, setFieldValue, setValues } =
  useZodForm(posSettingsSetInputSchema);

// watch
watch(posSettings, (settings) => {
  if (settings == null) return;
  setValues({
    ...settings.data,
  });
});

// methods
const submit = handleSubmit((v) => {
  posSettingUpsert({
    data: {
      printFooterNotes: v.printFooterNotes ? v.printFooterNotes : null,
      printHeaderNotes: v.printHeaderNotes ? v.printHeaderNotes : null,
    },
  });
});
</script>

<template>
  <MCard
    :loading="isPending || isFetching"
    :disabled="isPending || isFetching"
  >
    <template #title>
      <div class="flex items-center justify-between">
        <div>
          <h1 class="text-xl">
            {{ t("pos-settings") }}
          </h1>
        </div>
        <div></div>
      </div>
      <MDivider class="my-4" />
    </template>
    <template #text>
      <MForm
        class="flex flex-col gap-y-1"
        @submit="submit"
      >
        <MarkdownInput
          :label="t('print-header-notes')"
          name="printHeaderNotes"
          :modelValue="values.printHeaderNotes || ''"
          @update:modelValue="setFieldValue('printHeaderNotes', $event)"
        />
        <MarkdownInput
          :label="t('print-footer-notes')"
          name="printFooterNotes"
          :modelValue="values.printFooterNotes || ''"
          @update:modelValue="setFieldValue('printFooterNotes', $event)"
        />
        <MDivider class="my-4" />

        <MBtn
          :disabled="disableSubmit"
          :loading="isPending"
          type="submit"
          block
        >
          {{ t("update") }}
        </MBtn>
      </MForm>
    </template>
  </MCard>
</template>
