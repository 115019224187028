<script setup lang="ts">
// composables
const { t } = useI18n();

useHead({
  title: t("pos-settings"),
});
</script>

<template>
  <div class="p-4">
    <PointOfSaleSettingsForm />
  </div>
</template>
