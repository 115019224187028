<script setup lang="ts">
import { MdEditor } from "md-editor-v3";
import "md-editor-v3/lib/style.css";

interface Props {
  name: string;
  label: string;
}

// props
const props = defineProps<Props>();

// composables
const { theme } = useThemeStorage();
const { locale } = useI18n();

// state
const modelValue = defineModel<string>({
  required: true,
});
</script>

<template>
  <MExpandPanel :title="props.label">
    <MdEditor
      v-model="modelValue"
      :editorId="props.name"
      :theme="theme === 'dark' ? 'dark' : 'light'"
      :language="locale"
      :toolbarsExclude="[
        'github',
        'fullscreen',
        'pageFullscreen',
        'catalog',
        'htmlPreview',
        'mermaid',
        'katex',
        'save',
        'image',
        'code',
        'codeRow',
        'sub',
        'sup',
        'table',
        'underline',
      ]"
    />
  </MExpandPanel>
</template>
